<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered text-center" id="organization_chart_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Designation/Title</th>
              <th>Location</th>
              <th>Phone No.</th>
              <th>Department</th>
              <th>Assigned Location</th>
              <th>Parent/Top Management</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(organization_chart, index) in organization_charts" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ organization_chart.name }}</td>
              <td>{{ organization_chart.designation }}</td>
              <td>{{ organization_chart.location }}</td>
              <td>{{ organization_chart.phone_no }}</td>
              <td>{{ organization_chart.department.department_name }}</td>
              <td>
                <span v-for="(assigned_location, index) in organization_chart.assigned_locations" v-bind:key="index">
                  {{ index + 1 }}. {{ assigned_location }} <br>
                </span>
              </td>
              <td>{{ organization_chart.parent ? organization_chart.parent.name : 'N/A' }}</td>
              <td>
                <router-link :to="{ name: 'organizationChartUpdate', params: { organization_chart_id: organization_chart.id } }" class="btn btn-sm btn-info mr-2" tag="a">
                  <i class="fe fe-edit"/> Edit
                </router-link>
                <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(organization_chart.id)"><i class="fa fa-trash mr-1"></i>Delete</a>

              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="organization_charts.length === 0 && !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      organization_charts: [],
      loading: false,
      flag: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getOrganizationCharts()
  },
  methods: {
    getOrganizationCharts() {
      this.flag = true
      this.loading = true
      apiClient.get('api/organization-chart/list')
        .then(response => {
          this.loading = false
          this.flag = true
          const data = response.data
          this.organization_charts = data.organization_charts
        })
        .catch(error => {
          this.loading = false
          this.flag = true
          console.log(error)
        })
    },
    onDelete(organizationChartIdd) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/organization-chart/delete/' + organizationChartIdd).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getOrganizationCharts()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
